import React from 'react';
import Footer from '../footer/Footer';
import './Vault.scss';

const Vault = () => {

    return (
      <div className="Vault">
        <div className="main">
          <h1 className="title">THE VAULT</h1>
          <h3 className="words">Coming Soon!</h3>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    );
}

export default Vault;
