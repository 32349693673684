import React from 'react';

const Podcast = () => { 

    return (
      <>
        <h1>working</h1>
        <iframe
          title="Misguided Idiot"
          allow="autoplay *; encrypted-media *; fullscreen *"
          frameborder="0"
          height="450"
          style={{
            width: "100%",
            maxWidth: "1000px",
            overflow: "hidden",
            background: "transparent",
            border: "solid 3px red",
          }}
          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
          src="https://embed.podcasts.apple.com/us/podcast/ep-118-red-headed-countdown/id1304807444?i=1000522641531"
        ></iframe>
        <iframe
          title="Misguided Idiot"
          src="https://anchor.fm/misguidedidiot/embed"
          height="102px"
          width="400px"
          frameborder="0"
          scrolling="no"
        ></iframe>
        <iframe
          title="Misguided Idiot"
          allow="autoplay *; encrypted-media *; fullscreen *"
          frameborder="0"
          height="450"
          style={{
            width: "100%",
            maxWidth: "1000px",
            overflow: "hidden",
            background: "transparent",
            border: "solid 3px red",
          }}
          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
          src="https://embed.podcasts.apple.com/us/podcast/misguided-idiot-podcast/id1304807444"
        ></iframe>

        <iframe
          title="Misguided Idiot"
          src="https://open.spotify.com/embed/show/4t67QXrcrknaZzR07ZHNxs?utm_source=generator&t=0"
          width="100%"
          height="232"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>

        <iframe
          title="Misguided Idiot"
          src="https://open.spotify.com/embed/show/4t67QXrcrknaZzR07ZHNxs?utm_source=generator&theme=0&t=0"
          width="50%"
          height="25%"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        />
      </>
    );
};

export default Podcast;
