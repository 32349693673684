import React from 'react';
import './About.scss';
import Footer from '../footer/Footer';
import { FaMicrophoneAlt } from 'react-icons/fa';
import { RiFilmLine, RiFilePaper2Fill } from 'react-icons/ri';
import { GiPocketRadio } from 'react-icons/gi';
import { useSpring, animated } from 'react-spring';

const About = () => {
  const aboutMeBg = useSpring({ from: { opacity: 0, transform: 'translateY(200px)' }, opacity: 1, transition: '1.5s ease-out', transform: 'translateY(0px)' });
  const aboutMe = useSpring({ from: { opacity: 0, transform: 'translateY(-250px)' }, opacity: 1, transition: '1.5s ease-out', transform: 'translateY(0px)' });

  return (
    <div className='About'>

      <animated.div style={aboutMeBg} className='aboutMe'>
      </animated.div>
      <animated.h1 style={aboutMe} className='aboutH1'>About Me</animated.h1>

      <div className='bottom'>
        <div className='myStory'>
          <h1>My Story</h1>
          <p>
            {`I'm Wes Griffin from Portland, Oregon. 
            Honestly, if you want to know more about me, just listen to the podcast! It is probably the best insight into my soul. 
            In all honesty, it's like sitting in on a therapy session and you didnt even have to go to college. 
            So sit back, relax and watch me go insane one episode at a time.`}
          </p>
        </div>

        <div className='mySkills'>
          <div className='leftSkill'>
            <h1>My Skills</h1>
          </div>
          <div className='rightSkill'>
            <div>
              <RiFilmLine className='icon'/>
              <h3>Video</h3>
            </div>
            <div>
              <FaMicrophoneAlt className='icon'/>
              <h3>Podcasting</h3>
            </div>
            <div>
              <GiPocketRadio className='icon'/>
              <h3>Editing</h3>
            </div>
            <div>
              <RiFilePaper2Fill className='icon'/>
              <h3>Writing</h3>
            </div>
          </div>
        </div>

        <div className='experience'>
          <div className='leftEx'>
            <h1>Inspirations</h1>
          </div>
          <div className='rightEx'>
            <div>
              <h3>Bill Burr</h3>
            </div>
            <div>
              <h3>Steve Martin</h3>
            </div>
            <div>
              <h3>Martin Lawrence</h3>
            </div>
          </div>
        </div>

        <div className='footer'>
          <Footer className='foot' />
        </div>
      </div>
    </div>
  );
};

export default About;

// TODO: add in later
// <div className={styles.links}>
//   <div className={styles.linkLeft}>
//     <p>follow me</p>
//     <h1>LinkedIn</h1>
            
//     <Link 
//       className={styles.leftArrow}
//       to="route" 
//       onClick={(e) => 
//       {e.preventDefault(); window.open('https://www.linkedin.com/in/wesgriffincodes/');}
//       }>
//       <Arrow rotate='180deg' text={textLeft}/>
//     </Link>
//   </div>
//   <div className={styles.linkRight}>
//     <p>follow me</p>
//     <h1>Instagram</h1>
//     <Link 
//       to="route" 
//       onClick={(e) => 
//       {e.preventDefault(); window.open('https://www.instagram.com/codingclueless/');}
//       }>
//       <Arrow text={textRight}/>
//     </Link>   
//   </div>
// </div>;
