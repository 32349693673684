export const projects = [
  {
    title: 'Podcast',
    name: 'Misguided Idiot',
    number: 1,
    paragraph: `I'm Wes Griffin and welcome to the home of the Misguided Idiot! Take a look around, listen to an episode and behave yourself.`,
    arrow2Link: 'route',
    arrow2OnClick: 'https://open.spotify.com/show/4t67QXrcrknaZzR07ZHNxs?si=5e7ffddc86544318',
    arrow2Text: 'LISTEN ON SPOTIFY',
    arrowLink: 'route',
    arrowOnClick: 'https://podcasts.apple.com/us/podcast/misguided-idiot/id1304807444',
    arrowText: 'APPLE PODCAST',
    id: 1
  },
  {
    title: 'random stuff',
    name: 'Social Media',
    number: 2,
    paragraph: 'Bored? Looky, looky at what my mind thinks of. Come on... you know you want to!',
    arrowLink: 'route',
    arrowOnClick: 'https://www.instagram.com/misguidedidiot/',
    arrowText: 'INSTAGRAM',
    arrow2Link: 'route',
    arrow2OnClick: 'https://twitter.com/Misguidedidiot',
    arrow2Text: 'TWITTER',
    id: 2,
  },
  {
    title: 'old writings',
    name: 'THE VAULT',
    number: 0,
    paragraph: `Alright, I used to write...a lot! Take a look in the vault and see some goodies from the old days...`,
    arrowLink: '/vault',
    arrowOnClick: '',
    arrowText: 'OLD SHIT',
    id: 0,
  },
  {
    title: 'wes griffin',
    name: 'ABOUT ME',
    number: 3,
    paragraph: 'So you are curious who I am, huh? Well, then click the link below and get your fix...or not.',
    arrowLink: '/about',
    arrowOnClick: '',
    arrowText: 'MORE ABOUT WES',
    id: 3,
  },

];
